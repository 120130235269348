<template>
  <div class="d-flex ga-1 flex-wrap my-1">
    <Chip v-for="item in items" :key="item.id" density="compact">
      {{ item.name }}
    </Chip>
  </div>
</template>

<script lang="ts" setup generic="I">
import { get } from 'lodash';
import type { TableCellProp } from '@/types/table';
import type { GuidIdNamePairDto } from '@/api';

const props = defineProps<TableCellProp<I>>();
const items = computed(
  () => get(props.rowItem, props.colKey) as GuidIdNamePairDto[],
);
</script>
